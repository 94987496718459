/* eslint-disable no-unused-vars */

import { ref, computed } from '@vue/composition-api'

import { apiAgencies } from '@/api'
import store from '@/store'
import { env } from '@/libs/env'

import useToast from '@useToast'

export default function useClassBookingHandle() {
  const { toastSuccess, toastError } = useToast()
  const { isDevelopment } = env

  const tableColumnsVNA = isDevelopment ? [
    'passenger',
    'itinerary',
    'services',
    'amount',
    'total',
    'paymentStatus',
    'action',
  ] : [
    'passenger',
    'itinerary',
    'services',
    'amount',
    'total',
    // 'action',
    'paymentStatus',
  ]

  const tableColumns = ['passenger', 'itinerary', 'services', 'amount', 'total', 'action']

  const perfectScrollbarSettingsRight = {
    maxScrollbarLength: 60,
    wheelPropagation: true,
  }
  const fnAncillary = computed(
    () => store.getters['app-reservation/getFnModifyAddonsBySource'],
  )
  const bookingSource = computed(
    () => store.getters['app-reservation/getBookingSource'],
  )

  const getAirlineBySource = source => {
    if (['1S', '1S_CTRL'].includes(source)) return 'VN'
    if (['VJ', 'VZ'].includes(source)) return 'VJ'
    return source
  }

  const getAirCraftByIata = code => store.getters['globalConfig/getAirCraftByIata'](code) || code

  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  const sortBaggagesAirlines = (a, b) => (a?.details?.[0]?.weight || 1) - (b?.details?.[0]?.weight || 0)

  function sortBaggagesVJ(a, b) {
    const order = ['Bag', 'Oversize']
    const getOrderIndex = code => {
      for (let i = 0; i < order.length; i += 1) {
        if (code.startsWith(order[i])) {
          return i
        }
      }
      return order.length
    }

    const orderA = getOrderIndex(a.code)
    const orderB = getOrderIndex(b.code)
    if (orderA !== orderB) {
      return orderA - orderB
    }
    const weightA = a?.details?.[0]?.weight || 1
    const weightB = b?.details?.[0]?.weight || 0
    return weightA - weightB
  }

  function expandItemsQH(data) {
    const expandedData = []

    data.forEach(item => {
      if (item.quantity > 1) {
        for (let i = 0; i < item.quantity; i += 1) {
          const newItem = { ...item, quantity: 1, total: item.total / item.quantity }
          expandedData.push(newItem)
        }
      } else {
        expandedData.push(item)
      }
    })

    return expandedData
  }

  function enableDevelopEnv(funcName, source) { // NOTE: dev xong bật available của source trong selectOptions
    if (['baggage', 'seat', 'other'].includes(funcName) && isDevelopment) {
      if (['VN1A', 'VN1A_MT', 'TR'].includes(source)) {
        return true
      }
    }
    return false
  }

  return {
    fnAncillary,
    bookingSource,

    perfectScrollbarSettingsRight,

    tableColumnsVNA,
    tableColumns,

    delay,
    toastSuccess,
    toastError,
    getAirlineBySource,
    getAirCraftByIata,

    sortBaggagesAirlines,
    sortBaggagesVJ,

    expandItemsQH,
    enableDevelopEnv,
    isDevelopment,
  }
}
