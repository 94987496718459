import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'

import { resolveFnModifyAddonsBySource } from '@/constants/selectOptions'
import { apiReservation, apiBooking } from '@/api/'
import store from '@/store'

const defaultState = () => ({
  // Reservation
  bookingData: null,
  bookingId: null,

  seatMapData: [],
  isEmptySeatMapData: 'loading',
  ancillaryData: {},
})

const defaultSearchState = () => ({
  // ANCHOR generals
  loading: false,
  progressValue: null,
  isAddFlights: false,

  // ANCHOR Change Flight
  tripSelectedToChange: null,
  selectedFlightIndex: [],

  // ANCHOR search
  searchFlight: {}, // data search

  // ANCHOR result
  resultSearchFlight: [], // ket qua tim kiem chuyen bay
  isDomestic: null,
  selectedTrip: [],
  tabIndexResult: 0,
})

// extract phone, email contact
function extractAddressData(lst, source = null) {
  const result = []

  if (lst?.length === 0 && ['VJ', 'VZ'].includes(source)) {
    lst.push('0000000000')
  }

  for (let i = 0; i < lst?.length; i += 1) {
    const obj = {}
    const splitString = lst[i].split('|')

    if (splitString.length > 1) {
      obj.id = splitString[0]
      const addressSplit = splitString[1].split('-')

      if (addressSplit.length >= 2 && addressSplit[1].length > 5) {
        obj.prefix = addressSplit[0] || null
        obj.address = addressSplit[0] === '84' ? `0${addressSplit[1]}` : addressSplit[1]
        obj.type = addressSplit[2] || null
      } else {
        obj.address = addressSplit[0]
        obj.prefix = addressSplit[1] || null
      }
    } else {
      obj.id = null
      obj.address = lst[i] === '0000000000' ? '' : lst[i]
      obj.prefix = null
    }
    result.push(obj)
  }

  return result
}

function addIndexToSegment(itineraries, source) {
  let result = []
  // if (source === 'QH') {
  if (isEmpty(itineraries)) return result
  let indexSegment = 1
  result = itineraries.map((trip, tripIndex) => trip.map(segment => {
    const data = {
      ...segment,
      devItineraryIndex: String(tripIndex + 1),
      devSegmentIndex: String(indexSegment),
      devAddonsSegmentIndex: ['QH'].includes(source) ? String(indexSegment) : String(segment.segmentId),
    }
    indexSegment += 1
    return data
  }))
  // } else {
  //   result = itineraries
  // }
  return result
}

// SECTION function fetch unknown iataCode
async function fetchUnknownAirport(itineraries, getters, dispatch) {
  const unknownAirport = []
  itineraries.forEach(trip => {
    if (!trip || !trip?.length) return
    trip.forEach(segment => {
      const airportDeparture = segment.departure.iataCode
      const airportArrival = segment.arrival.iataCode
      if (!unknownAirport.includes(airportDeparture) && !getters?.getAirportByAirportCode(airportDeparture, true)) {
        unknownAirport.push(airportDeparture)
      }
      if (!unknownAirport.includes(airportArrival) && !getters?.getAirportByAirportCode(airportArrival, true)) {
        unknownAirport.push(airportArrival)
      }
      if (segment.stopAp.length) {
        segment.stopAp.forEach(stop => {
          if (!unknownAirport.includes(stop) && !getters?.getAirportByAirportCode(stop, true)) {
            unknownAirport.push(stop)
          }
        })
      }
    })
  })
  if (unknownAirport.length) {
    await dispatch('fetchAirportsByCodes', unknownAirport)
  }
}
// !SECTION

export default {
  namespaced: true,
  state: {
    airports: [], // cac airport da tim kiem
    airportGroup: {}, // airport group api
    ...defaultState(),
    ...defaultSearchState(),

    hideFeature: false,
  },
  getters: {
    getLoading: state => state.loading,
    getIsAddFlights: state => state.isAddFlights,
    getBookingData: state => {
      let result

      if (state.bookingData) {
        const mapPhone = state.bookingData?.contact?.paxInfo?.phone ?? []
        const mapEmail = state.bookingData?.contact?.paxInfo?.email ?? []

        result = {
          ...state.bookingData,
          itineraries: addIndexToSegment(
            // eslint-disable-next-line arrow-body-style
            state.bookingData.itineraries.filter(trip => trip.some(segment => {
              // VU không lọc status
              return segment.airline !== 'VU' ? !['HX', 'WK'].includes(segment.status) : true
            })),
            state.bookingData.source,
          ),
          contact: {
            ...state.bookingData.contact,
            paxInfo: {
              ...state.bookingData.contact.paxInfo,
              phone: extractAddressData(mapPhone, state.bookingData.source),
              email: extractAddressData(mapEmail),
            },
          },
        }
      }
      return result
    },
    getBookingSource: state => state?.bookingData?.source,
    getFnModifyAddonsBySource: state => resolveFnModifyAddonsBySource(state?.bookingData?.source),
    getCancelFlight: state => state.bookingData?.itineraries.map(trip => trip.filter(segment => segment?.status === 'HX')).flat(1),

    // ANCHOR Search
    getSearchFlight: state => state.searchFlight,
    getSearchFlightArray: state => {
      let searchFlightArray = []
      const { searchFlight } = state
      if (searchFlight.flights) {
        if ((searchFlight.flights?.length > 1 && searchFlight.type === 'MC') || searchFlight.type === 'OW') {
          searchFlightArray = [...searchFlight.flights]
        }
        if (searchFlight.flights?.length === 1 && searchFlight.type === 'RT') {
          searchFlightArray.push(
            {
              startPoint: searchFlight.flights[0].startPoint,
              endPoint: searchFlight.flights[0].endPoint,
              departDate: searchFlight.flights[0].departDate,
            },
            {
              startPoint: searchFlight.flights[0].endPoint,
              endPoint: searchFlight.flights[0].startPoint,
              departDate: searchFlight.flights[0].returnDate,
            },
          )
        }
      } else {
        if ((searchFlight.flight && searchFlight.type === 'MC') || (searchFlight.type === 'OW')) {
          searchFlightArray = [searchFlight.flight]
        }
        if (searchFlight.flight && (searchFlight.type === 'RT')) {
          searchFlightArray.push(
            {
              startPoint: searchFlight.flight.startPoint,
              endPoint: searchFlight.flight.endPoint,
              departDate: searchFlight.flight.departDate,
            },
            {
              startPoint: searchFlight.flight.endPoint,
              endPoint: searchFlight.flight.startPoint,
              departDate: searchFlight.flight.returnDate,
            },
          )
        }
      }
      return searchFlightArray
    },

    // ANCHOR Airport
    getAirportByAirportCode: state => (airportCode, ignoreGroup = false, removeCountryName = false) => {
      let airport = state.airports?.find(airport => airport.iata === airportCode)
      if (airport) {
        airport.name = `${airport.city}${!removeCountryName && airport.countryName !== 'Việt Nam' && airport.countryName !== airport.city ? `, ${airport.countryName}` : ''}`
        return airport
      }
      if (ignoreGroup) return null
      airport = state.airportGroup?.airports?.find(airport => airport.iata === airportCode)
      if (airport) airport.name = removeCountryName ? airport?.name.split(',')?.[0] : airport?.name.replace(', Việt Nam', '')
      return airport
    },
    getAirportGroup: state => state.airportGroup,
    getProgressValue: state => state.progressValue,

    // ANCHOR result
    getResultSearchFlight: (state, getters) => {
      const result = []
      getters.getSearchFlightArray.forEach(() => result.push([]))
      state.resultSearchFlight.forEach(resultItem => {
        const { itineraryId, trips } = resultItem
        const index = itineraryId - 1
        if (!result[index]) result[index] = []
        result[index].push(...trips)
      })
      return result
    },

    getTabIndex: state => state.tabIndexResult,
    getIsDomestic: state => state.isDomestic,
    getSelectedTrip: state => state.selectedTrip,
    getSelectedFlightIndex: state => state.selectedFlightIndex,

    // ANCHOR Change Flight
    getOldItineraries: (state, getters) => {
      const result = []
      const indexArr = getters.getSelectedFlightIndex
      const source = getters.getBookingData?.source || ''

      if (['VN1A', 'VN1A_MT', '1A'].includes(source)) {
        for (
          let index = 0;
          index < indexArr.length;
          index += 1
        ) {
          result.push(getters.getBookingData.itineraries[indexArr[index]])
        }
      }

      if (['VJ', 'QH'].includes(source)) {
        result.push(getters.getBookingData.itineraries[indexArr[0]])
      }

      return result
    },

    getItinerariesByIndexSelected: (state, getters) => {
      const result = []
      const data = getters.getBookingData
      const indexArr = getters.getSelectedFlightIndex
      if (indexArr.length) {
        indexArr.forEach(i => result.push(data.itineraries[i]))
      }
      return result
    },

    // Modify Add-ons
    getSeatMapData: state => state.seatMapData,
    getIsEmptySeatMapData: state => state.isEmptySeatMapData,
    getAncillaryData: state => state.ancillaryData,

    // OTHER
    getHideFeature: state => state.hideFeature,
  },

  // NOTE MUTATIONS
  mutations: {
    RESET_STORE(state) {
      Object.assign(state, defaultState())
    },
    RESET_SEARCH_STORE(state) {
      Object.assign(state, defaultSearchState())
    },
    SET_BOOKING_DATA(state, val) {
      if (state.bookingData) {
        state.bookingData = null
      }
      state.bookingData = val
    },
    SET_BOOKING_ID(state, val) {
      state.bookingId = val
    },

    // Change Flight
    SET_FLIGHTS_INDEX(state, val) {
      state.selectedFlightIndex = val
    },

    // ANCHOR airport
    PUSH_AIRPORTS(state, airportList) {
      airportList.forEach(e => {
        if (!state.airports.find(ele => ele.id === e.id)) state.airports.push(e)
      })
    },
    SET_AIRPORT_GROUP(state, val) {
      state.airportGroup = val
    },
    // ANCHOR search
    UPDATE_SEARCH_FLIGHT(state, searchFlightData) {
      state.searchFlight = searchFlightData
    },
    CLEAR_RESULT_SEARCH_FLIGHT(state) {
      state.resultSearchFlight = []
    },
    SET_LOADING(state, val) {
      state.loading = val
    },
    // ANCHOR result
    PUSH_RESULT_SEARCH_FLIGHT(state, resultItem) {
      state.resultSearchFlight.push(resultItem)
    },
    SET_PROGRESS_VALUE(state, val) {
      state.progressValue = val
    },
    SET_IS_DOMESTIC(state, val) {
      state.isDomestic = val
    },
    SET_TAB_INDEX(state, val) {
      state.tabIndexResult = val
    },
    SET_SELECTED_TRIP(state, val) {
      state.selectedTrip = val
    },
    SET_ADD_FLIGHT(state, val) {
      state.isAddFlights = val
    },
    SET_SEAT_MAP_DATA(state, val) {
      state.seatMapData = val
    },
    SET_EMPTY_DATA_SEAT_MAP(state, val) {
      state.isEmptySeatMapData = val
    },
    SET_ANCILLARY_DATA(state, val) {
      state.ancillaryData = val
    },

    // OTHER
    SET_HIDE_FEATURE(state, val) {
      state.hideFeature = val
    },
  },

  // NOTE - ACTION
  actions: {
    resetStore({ commit }) {
      commit('RESET_STORE')
    },

    resetSearchStore({ commit }) {
      commit('RESET_SEARCH_STORE')
    },

    fetchBookingById({ commit, getters, dispatch }, { id, setId }) {
      return new Promise((resolve, reject) => {
        apiReservation
          .fetchBookingById(id)
          .then(async response => {
            const allItineraries = [
              response.data.itineraries,
              response.data.multiCityBookings ? response.data.multiCityBookings.map(mc => mc.itineraries).flat()
                : (response.data.roundtripBooking?.itineraries ?? []),
            ].flat()
            await fetchUnknownAirport(allItineraries, getters, dispatch)
            if (setId) {
              commit('SET_BOOKING_DATA', response.data)
              commit('SET_BOOKING_ID', id)
            }
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    fetchPastDayBookingById({ commit, getters, dispatch }, { id }) {
      return new Promise((resolve, reject) => {
        apiReservation
          .fetchPastDayBookingById(id)
          .then(async response => {
            const allItineraries = [
              response.data.itineraries,
              response.data.multiCityBookings ? response.data.multiCityBookings.map(mc => mc.itineraries).flat()
                : (response.data.roundtripBooking?.itineraries ?? []),
            ].flat()
            await fetchUnknownAirport(allItineraries, getters, dispatch)
            commit('SET_BOOKING_DATA', response.data)
            commit('SET_BOOKING_ID', id)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    reFetchBookingById({
      commit, state, getters, dispatch,
    }, { ignoreLoading }) {
      return new Promise((resolve, reject) => {
        if (!ignoreLoading) store.dispatch('app/setLoading', true)
        apiReservation
          .fetchBookingById(state.bookingId)
          .then(async response => {
            const allItineraries = [
              response.data.itineraries,
              response.data.multiCityBookings ? response.data.multiCityBookings.map(mc => mc.itineraries).flat()
                : (response.data.roundtripBooking?.itineraries ?? []),
            ].flat()
            await fetchUnknownAirport(allItineraries, getters, dispatch)
            commit('SET_BOOKING_DATA', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => {
            if (!ignoreLoading) {
              store.dispatch('app/setLoading', false)
            }
          })
      })
    },

    retrieveBooking({ commit, getters, dispatch }, payload = {}) {
      return new Promise((resolve, reject) => {
        apiReservation
          .retrieveBooking(payload)
          .then(async response => {
            await fetchUnknownAirport(response.itineraries, getters, dispatch)
            commit('SET_BOOKING_DATA', response)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    setBookingData({ commit }, bookingData) {
      commit('SET_BOOKING_DATA', bookingData)
    },

    fetchBookings(ctx, params) {
      return apiReservation.fetchBookings(params)
    },

    fetchPastDayBookings(ctx, params) {
      return apiReservation.fetchPastDayBookings(params)
    },

    updateNote(ctx, dataToUpdate) {
      return apiReservation.updateNote(dataToUpdate.id, {
        note: dataToUpdate.dataToUpdate.note,
      })
    },

    updateAutoIssueTime(ctx, dataToUpdate) {
      return apiReservation.updateAutoIssueTime(dataToUpdate.id, {
        ...dataToUpdate.dataToUpdate,
      })
    },

    activeReservation(ctx, id) {
      return apiReservation.activeReservation(id)
    },

    deActiveReservation(ctx, id) {
      return apiReservation.deActiveReservation(id)
    },

    banBooking(ctx, { id, payload }) {
      return apiReservation.banBooking(id, payload)
    },

    unbanBooking(ctx, id) {
      return apiReservation.unbanBooking(id)
    },

    submitBooking(ctx, { id, payload }) {
      return apiReservation.submitBooking(id, payload)
    },

    sendSubmitBookingMail(ctx, id) {
      return apiReservation.sendSubmitBookingMail(id)
    },

    reBookReservation(ctx, { id, params }) {
      return apiReservation.reBookReservation(id, params)
    },

    exportBooking(ctx, params, fileName = 'Booking-List') {
      return apiReservation.exportBooking(params, fileName)
    },

    ancillaryPayments(ctx, payload = {}) {
      return apiReservation.ancillaryPayments(payload)
      // return new Promise((resolve, reject) => {
      //   apiReservation.ancillaryPayments(payload)
      //     .then(response => {
      //       commit('SET_BOOKING_DATA', response)
      //       return resolve(response)
      //     })
      //     .catch(error => reject(error))
      // })
    },

    modifyPaxInfo(ctx, payload = {}) {
      const { source } = payload
      if (['VN1A', 'VN1A_MT'].includes(source)) {
        return apiReservation.commandModifyPaxInfo(payload)
      }
      return apiReservation.modifyPaxInfo(payload)
    },

    paxInfoPrice(ctx, payload = {}) {
      return apiReservation.paxInfoPrice(payload)
    },

    fetchListAncillary({ commit }, params) {
      // return apiReservation.fetchListAncillary(params)
      return new Promise((resolve, reject) => {
        apiReservation
          .fetchListAncillary(params)
          .then(response => {
            commit('SET_ANCILLARY_DATA', response)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    removeListAncillaryData({ commit }, data = {}) {
      commit('SET_ANCILLARY_DATA', data)
    },

    modifyAncillary(ctx, payload = {}) {
      return apiReservation.modifyAncillary(payload)
    },

    fetchSeatMap({ commit }, params) {
      commit('SET_EMPTY_DATA_SEAT_MAP', 'loading')

      return new Promise((resolve, reject) => {
        apiReservation
          .fetchSeatMap(params)
          .then(response => {
            if (isEmpty(response.items)) {
              commit('SET_EMPTY_DATA_SEAT_MAP', 'empty')
            } else {
              commit('SET_EMPTY_DATA_SEAT_MAP', 'null')
              commit('SET_SEAT_MAP_DATA', response.items)
            }
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    removeSeatMapData({ commit }, data = []) {
      commit('SET_EMPTY_DATA_SEAT_MAP', 'loading')
      commit('SET_SEAT_MAP_DATA', data)
    },

    modifyReserveSeat(ctx, payload = {}) {
      return apiReservation.modifyReserveSeat(payload)
    },
    modifyCancelSeat(ctx, payload = {}) {
      return apiReservation.modifyCancelSeat(payload)
    },

    flightPayment(ctx, payload = {}) {
      return apiReservation.flightPayment(payload)
    },

    cancelFlightPrice(ctx, payload = {}) {
      return apiReservation.cancelFlightPrice(payload)
    },

    cancelFlight(ctx, payload = {}) {
      return apiReservation.cancelFlight(payload)
    },
    cancelFlight1G(ctx, payload = {}) {
      return apiReservation.cancelFlight1G(payload)
    },

    // cancel flight VN1A
    commandsGetBooking(ctx, payload = {}) {
      return apiReservation.commandsGetBooking(payload)
    },
    commandsCancelSegment(ctx, payload = {}) {
      return apiReservation.commandsCancelSegment(payload)
    },

    separateBooking(ctx, payload = {}) {
      return apiReservation.splitPnr(payload)
    },

    calculatePriceBooking(ctx, payload = {}) {
      return apiReservation.calculatePriceBooking(payload)
    },

    // recalculatePriceCommand(ctx, payload = {}) {
    //   return apiBooking.recalculatePriceCommand(payload)
    // },
    recalculatePriceCommandAddDob(ctx, payload = {}) {
      return apiBooking.recalculatePriceCommandAddDob(payload)
    },
    recalculatePriceItinerary(ctx, payload = {}) {
      return apiBooking.recalculatePriceItinerary(payload)
    },

    cleanAirPricingConversion(ctx, payload = {}) {
      return apiReservation.cleanAirPricingConversion(payload)
    },

    addFlights(ctx, payload = {}) {
      return apiReservation.addFlights(payload)
    },

    changeFlights(ctx, payload = {}) {
      return apiReservation.changeFlights(payload)
    },

    addFlightsPrice(ctx, payload = {}) {
      return apiReservation.addFlightsPrice(payload)
    },

    changeFlightsPrice(ctx, payload = {}) {
      return apiReservation.changeFlightsPrice(payload)
    },

    sendEmail(ctx, payload) {
      return apiReservation.sendEmail(payload)
    },

    sendEmailCommand(ctx, payload) {
      return apiReservation.sendEmailCommand(payload)
    },

    setSelectedFlightIndex({ commit }, ids) {
      commit('SET_FLIGHTS_INDEX', ids)
    },

    // ANCHOR airport
    fetchAirports({ commit }, params) {
      return new Promise((resolve, reject) => {
        apiBooking
          .fetchAirports(params)
          .then(response => {
            commit('PUSH_AIRPORTS', response.data.items)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchAirportGroup({ commit }) {
      return new Promise((resolve, reject) => {
        apiBooking
          .fetchAirportGroup()
          .then(response => {
            commit('SET_AIRPORT_GROUP', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    async fetchAirportsByCodes({ commit }, params) {
      if (!params?.length) return null
      const response = await apiBooking
        .fetchAirportsByCodes({
          airportCodes: params.length ? params.join(',') : null,
        })
      commit('PUSH_AIRPORTS', response.data)
      return response
    },

    // ANCHOR search
    searchFlight({ commit }, payload) {
      // v2
      commit('UPDATE_SEARCH_FLIGHT', payload)
      commit('CLEAR_RESULT_SEARCH_FLIGHT')
      return apiBooking.searchFlightv2(payload)
    },
    searchFlightForModify({ commit }, payload) {
      // search for modify
      commit('UPDATE_SEARCH_FLIGHT', payload)
      commit('CLEAR_RESULT_SEARCH_FLIGHT')

      const {
        flights,
        airlines,
        adult,
        child,
        infant,
        currency,
        numberOfStop,
        pnrNumber,
        oldItineraryId,
        type,
      } = payload

      const searchPayload = {
        adult,
        child,
        infant,
        currency,
        numberOfStop,
        pnrNumber,
        oldItineraryId,
        airline: airlines[0],
        source: airlines,
      }

      if (airlines.some(airline => ['VN1A', 'VN1A_MT'].includes(airline))) {
        searchPayload.flights = flights
        searchPayload.type = type
      } else {
        searchPayload.flight = {
          startPoint: flights[0].startPoint,
          endPoint: flights[0].endPoint,
          departDate: flights[0].departDate,
          returnDate: null,
        }
      }

      return apiReservation.searchForModify(searchPayload)
    },
    getFlightSearchOperation(_ctx, params) {
      // v2
      return apiBooking.getFlightSearchOperation(params)
    },
    setLoading({ commit }, val) {
      commit('SET_LOADING', val)
    },
    getResourceItinerary(_ctx, params) {
      // v2
      return apiBooking.getResourceItinerary(params)
    },

    // ANCHOR result
    pushResultSearchFlight({ commit }, resultItem) {
      const modifyTrips = resultItem.trips.map(resItem => {
        resItem.fareOptions = sortBy(resItem.fareOptions, [
          item => item.afterMultiple.total,
        ])
        return resItem
      })
      commit('PUSH_RESULT_SEARCH_FLIGHT', {
        ...resultItem,
        trips: modifyTrips,
      })
    },
    setProgressValue({ commit }, val) {
      const value = val === 100 ? null : val
      commit('SET_PROGRESS_VALUE', value)
    },
    setIsDomestic({ commit }, val) {
      commit('SET_IS_DOMESTIC', val)
    },
    setTabIndex({ commit }, val) {
      commit('SET_TAB_INDEX', val)
    },
    setSelectedTrip({ commit }, val) {
      commit('SET_SELECTED_TRIP', val)
    },
    setIsAddFlights({ commit }, val) {
      commit('SET_ADD_FLIGHT', val)
    },

    deleteResultSearch({ commit }) {
      commit('CLEAR_RESULT_SEARCH_FLIGHT')
    },

    searchClassBooking({ commit }, payload) {
      commit('UPDATE_SEARCH_FLIGHT', payload)
      return apiBooking.searchClassBooking(payload)
    },

    // calculatePriceClassBooking(ctx, params) {
    //   return apiBooking.calculatePriceClassBooking(params)
    // },

    modifyDocuments(ctx, payload) {
      return apiReservation.modifyDocuments(payload)
    },

    modifyFrequentFlyer(ctx, payload) {
      return apiReservation.modifyFrequentFlyer(payload)
    },

    fetchEticketByNumber(ctx, payload) {
      return apiReservation.fetchEticketByNumber(payload)
    },

    voidTicket(ctx, payload) {
      return apiReservation.voidTicket(payload)
    },

    voidTicketV2(ctx, payload) {
      return apiReservation.voidTicketV2(payload)
    },

    eTicketUpdates(ctx, payload) {
      return apiReservation.eTicketUpdates(payload)
    },

    refundTicketAmount(ctx, payload) {
      return apiReservation.refundTicketAmount(payload)
    },

    refundTickets(ctx, payload) {
      return apiReservation.refundTickets(payload)
    },

    exchangeTicketAmount(ctx, payload) {
      return apiReservation.exchangeTicketAmount(payload)
    },

    exchangeTickets(ctx, payload) {
      return apiReservation.exchangeTickets(payload)
    },

    getCommissions(ctx, params) {
      return apiReservation.getCommissions(params)
    },
    checkTicketing(ctx, id) { // for 1G
      return apiReservation.checkTicketing(id)
    },
    ticketing(ctx, payload) {
      return apiReservation.ticketing(payload)
    },
    shortenBooking(ctx, payload) {
      return apiReservation.shortenBooking(payload)
    },
    shortenEticket(ctx, payload) {
      return apiReservation.shortenEticket(payload)
    },
    // retrieveTicket(ctx, payload) {
    //   return apiReservation.retrieveTicket(payload)
    // },
    confirmSegments(ctx, payload) {
      return apiReservation.confirmSegments(payload)
    },
    confirmFlightsSegments(ctx, payload) {
      return apiReservation.confirmFlightsSegments(payload)
    },
    modifyRemarks(ctx, payload) {
      return apiReservation.modifyRemarks(payload)
    },
    osiFlight(ctx, payload) {
      return apiReservation.commandAddOsi(payload)
    },
    sendQueue(ctx, payload) {
      return apiReservation.commandAddQueues(payload)
    },
    addDocumentsVN1A(ctx, payload) {
      return apiReservation.commandAddDocuments(payload)
    },
    bookSpecialServices(ctx, payload) {
      return apiReservation.bookSpecialServices(payload)
    },

    getListSpecialServices(ctx, payload) { // lounge,
      return apiReservation.getListSpecialServices(payload)
    },

    createSpecialServices(ctx, payload) { // lounge,
      return apiReservation.createSpecialServices(payload)
    },

    getInsurancePlans(ctx, payload) { // insurance
      return apiReservation.getInsurancePlans(payload)
    },
    bulkCreateInsuranceDraft(ctx, payload) { // insurance
      return apiReservation.bulkCreateInsuranceDraft(payload)
    },
    updateInsuranceDraft(ctx, payload) { // insurance
      return apiReservation.updateInsuranceDraft(payload)
    },
    deleteInsuranceDraft(ctx, payload) { // insurance
      return apiReservation.deleteInsuranceDraft(payload)
    },
    createInsuranceCoverNote(ctx, payload) { // insurance
      return apiReservation.createInsuranceCoverNote(payload)
    },
    editInsuranceCoverNote(ctx, payload) { // insurance
      return apiReservation.editInsuranceCoverNote(payload)
    },
    createInsurancePolicy(ctx, payload) { // insurance
      return apiReservation.createInsurancePolicy(payload)
    },
    cancelInsurance(ctx, payload) { // insurance
      return apiReservation.cancelInsurance(payload)
    },

    setLowFareTracking(ctx, { id, payload }) {
      return apiReservation.setLowFareTracking({ id, payload })
    },

    cancelLowFareTracking(ctx, { id, payload }) {
      return apiReservation.cancelLowFareTracking({ id, payload })
    },

    customServiceFee(ctx, { id, payload }) {
      return apiReservation.customServiceFee({ id, payload })
    },

    stopPaymentReminder(ctx, { id }) {
      return apiReservation.stopPaymentReminder({ id })
    },

    enableEditOutside(ctx, id) {
      return apiReservation.enableEditOutside(id)
    },

    assignBooking(ctx, { id, payload }) {
      return apiReservation.assignBooking({ id, payload })
    },

    getAuditLogsFromSource(ctx, payload) {
      return apiReservation.getAuditLogsFromSource(payload)
    },

    addMultiFftv(ctx, payload) {
      return apiReservation.addMultiFftv(payload)
    },
    // HIDE FEATURE
    toggleHideFeature({ commit, state }) {
      commit('SET_HIDE_FEATURE', !state.hideFeature)
    },
    setHideFeature({ commit }, value) {
      commit('SET_HIDE_FEATURE', value)
    },

    // HIDE FARE BOOKING
    hideFareBooking(_ctx, id) {
      return apiReservation.hideFareBooking(id)
    },
  },
}
